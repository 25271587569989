<template>
    <div class="store-info">
     <button @click="goBack" class="arrow-btn">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <div class="background">企业名称填写</div>
      <div class="box">
        <div class="form">
          <div class="from-item">
            <div class="label" style="font-size: 15px;"><span style="color: red;">*</span>企业名称</div>
            <input type="text" v-model="enterpriseName" class="input" placeholder="请输入企业名称" />
          </div>
          <span style="font-size: 15px;color: red;">注意：需要输入企业的全称并且不要有错别字(名称中含有括号要用中文括号)</span>
          <br />
          <!-- <span style="font-size: 15px;color: red;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;(名称中含有括号要用中文括号)</span> -->
            <div class="from-item">
              <div class="label" style="font-size: 15px;color: blue;">查询结果:</div>
            <input type="text" v-model="result" class="input" placeholder="" />
            </div>
           
        <div class="btn-box">
            <el-button v-if="!showTime"  type="primary" class="btn" @click="summmit">查询企业</el-button>
            <el-button v-else   type="primary" class="btn">{{ yanzma }}</el-button>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { ElMessage } from 'element-plus'
  // import { value, parcel, store, billImg, orderImg } from './storeInfo'
  const result=ref()
  let num = ref(59)
let timer = ref(null)
let yanzma = ref()
  const router = useRouter()
  const route = useRoute()
  const enterpriseName =ref(localStorage.getItem('enterpriseName')||'')
  let showTime = ref(false)

let getYzma = ref(true)


  //获取手机号
  let userMobile=route.query.userMobile;

  //查询企业
  const summmit = () => {
    if ( !enterpriseName.value  ) {
      ElMessage.error('请填写企业名称')
      return;
    }
  if (showTime.value) {
    return
  }

  if (getYzma.value) {
    showTime.value = true;
    yanzma.value = `60秒后再次查询`;
    getYzma.value = false;

    num.value = 60; // 重置倒计时的秒数为初始值
    clearInterval(timer.value); // 清除之前的计时器，确保只有一个计时器在工作

    timer.value = setInterval(() => {
      if (num.value <= 0) {
        clearInterval(timer.value);
        showTime.value = false;
        getYzma.value = true;
      } else {
        showTime.value = true;
        yanzma.value = `${num.value}秒后再次查询`;
        num.value--;
      }
    }, 1000)


    //构造请求参数

    const datas = {
  phone: userMobile,
  enterpriseName: enterpriseName.value,
};


     // 构造请求头
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('tenant-id', '1');

    // 发送请求
    fetch('/admin-api/crm/enterprise-inquiry-log/create', {
      method: 'POST',
      headers: headers,
      body:JSON.stringify(datas) 
    })
      .then(response => response.json())
      .then(({ data }) => {
        if(data==0){
       result.value='该企业未与电商融平台合作'
        }else{
          result.value='该企业已与电商融平台合作'
        }
      })
      .catch(error => {
        console.error('Error:', error);

      });
  }


}
  

const goBack = () => {
  router.push('/')
}

  
  </script>
  <style lang="scss" scoped>
  .store-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .background {
      width: 100%;
      height: 15.31rem;
      background-color: #4095e5;
      z-index: -1;
      font-size: 1.43rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-family: PingFangSC-regular;
    }
  
    .box {
      width: 22.13rem;
      // height: 29.81rem;
      background-color: #ffffff;
      border-radius: 0.62rem;
      box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
      margin-top: -3.94rem;
      padding: 0 1.875rem 1.875rem 1.875rem;
    }
  
    .from-item {
      display: flex;
      line-height: 3.31rem;
      font-size: 0.88rem;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid rgba(187, 187, 187, 1);
  
      .label {
        color: #4f4f4f;
        font-family: PingFangSC-regular;
  
      }
  
      .input {
        border: none;
        display: flex;
        font-family: PingFangSC-regular;
        width: 200px;
      }
  
      .input:focus {
        outline: none;
      }
  
      .select {
        width: 10.31rem;
        font-family: PingFangSC-regular;
        border: none;
        line-height: 3.31rem;
      }
    }
  
    .from-item-order {
      flex-direction: column;
      align-items: flex-start;
      border-bottom: none;
  
      .content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
  
      .img-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
  
        .imgs {
          position: relative;
        }
  
        .avatar {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 4px;
          object-fit: cover;
          margin-right: 0.85rem;
          margin-bottom: 0.85rem;
          padding: 0.31rem;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
  
        .delete-icon {
          width: 1rem;
          height: 1rem;
          position: absolute;
          right: 0.8rem;
          top: 0;
        }
      }
  
      .avatar-uploader {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .avatar-uploader-icon {
          padding: 1.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-bottom: 0.85rem;
        }
      }
    }
  
    .jump {
      color: #4095e5;
      font-size: 0.875rem;
      font-family: PingFangSC-regular;
      display: block;
      text-align: center;
    }
  
    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 1.875rem;
  
      .btn {
        width: 14.56rem;
        height: 2.75rem;
        border-radius: 1rem;
        background-color: #0581fe;
        font-family: PingFangSC-regular;
      }
    }
  
    .avatar {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 0.85rem;
      margin-bottom: 0.85rem;
      padding: 0.31rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  
  .el-input__wrapper {
    box-shadow: none;
  }
  .arrow-btn {
    background-color: #4095e5;
    width: 100%;
  }
  
  </style>