// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';

import enterpriseInfo from '../views/enterpriseInfo.vue';
import TwoLogin from '../views/TwoLogin.vue';

const routes = [
    {
        path:'/',
        name:'TwoLogin',
        component:TwoLogin
    },
    {
        path:'/enterpriseInfo',
        name:'enterpriseInfo',
        component:enterpriseInfo
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
