<template>
    <div class="w-screen">
      <!-- <NavBar title="电商融" :icon="icon" /> -->
      <div class="w-screen bg-blue-500 h-[40vh]">
        <p class="w-full absolute top-[10vh] text-xl text-[#fff] text-center">企业信息查询</p>
      </div>
      <div class="w-[90%] bg-[#fff] p-10 pt-5 absolute left-[50%] top-[30vh] translate-x-[-50%] rounded-2xl shadow-2xl">
        <div class="w-[100%] m-auto mt-4 input">
          <input type="tel" class="appearance-none border-0 p-1 focus:outline-none focus:border-0 text-sm"
            placeholder="请输入手机号" @input="phoneInput" @blur="phoneBlur" v-model="userMobile" />
          <div class="phone-tip" ref="phoneTipRef">您输入的手机号码有误</div>
          <hr class="mt-2 b-2" />
          <div class="flex justify-between mt-6">
            <input type="tel" class="appearance-none border-0 p-2 focus:outline-none focus:border-0 text-sm w-[50%]"
              placeholder="请输入验证码" @input="inputyanzm" v-model="yanzm" />
            <p v-if="!showTime" class="text-[#4095E5] mt-2 text-sm" @click="getYzm">获取验证码</p>
            <p v-else class="text-[#CECECE] mt-2">{{ yanzma }}</p>
          </div>
          <hr class="mt-2 b-2" />
          <button class="rounded-[30px] w-[80%] h-[3rem] bg-[#0581FE] m-auto block mt-10 text-[#fff] text-sm"
            @click="login">登录</button>
        </div>
      </div>
      <div v-if="showMessageBox"
        class="w-[40vw] p-2 absolute bg-[#fff] left-[50%] top-[40vh] translate-x-[-50%] rounded-xl border-[#C0C0C0] border-2">
        <h2 class="text-lg text-center">用户协议及隐私保护</h2>
        <p class="text-xs w-[90%] m-auto">为了让你在安全，放心的情况下使用。请阅读并同意 <span class="text-[#4095E5] text-sm">《xxx用户协议》</span></p>
        <hr class="mt-2 b-2 border-2 w-[90%] m-auto mb-2" />
        <h2 class="text-lg text-center text-[#0075FF]" @click="agree(0)">同意并继续</h2>
        <hr class="mt-2 b-2 border-2 w-[90%] m-auto mb-2" />
        <h2 class="text-lg text-center text-[#0075FF]" @click="agree(1)">不同意</h2>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { useRouter} from 'vue-router'
  import NavBar from '@/components/NavBar/NavBar.vue'
  import { ElMessage } from 'element-plus'
  import { useRoute } from 'vue-router';
  
  
  
  const icon = require('../assets/riLine-close-line.svg')
  const phoneTipRef = ref(null)
  const router = useRouter()
  const route=useRoute()
  
  let num = ref(59)
  let timer = ref(null)
  let yanzma = ref()
  let showTime = ref(false)
  let userMobile = ref(localStorage.getItem('mobile') || '')
  let yanzm = ref(localStorage.getItem('yanzm') || '')
  let checked1 = ref(false)
  let showMessageBox = ref(false)
  let getYzma = ref(true)
  localStorage.setItem('mobile', '')
  localStorage.setItem('yanzm', '')
  
  
  
  const getYzm = () => {
    if (showTime.value) {
      return
    }
  
    console.log(userMobile.value)
  
    const reg = /^1[0-9]{10}$/
    if (!userMobile.value) {
      ElMessage.error('请输入手机号')
      console.log(userMobile.value);
      return;
    }
  
    if (!reg.test(userMobile.value)) {
      ElMessage.error('请输入正确的手机号')
      return;
    }
  
    
  
    if (getYzma.value) {
      showTime.value = true;
      yanzma.value = `60秒后重试`;
      getYzma.value = false;
  
      num.value = 60; // 重置倒计时的秒数为初始值
      clearInterval(timer.value); // 清除之前的计时器，确保只有一个计时器在工作
  
      timer.value = setInterval(() => {
        if (num.value <= 0) {
          clearInterval(timer.value);
          showTime.value = false;
          getYzma.value = true;
        } else {
          showTime.value = true;
          yanzma.value = `${num.value}秒后重试`;
          num.value--;
        }
      }, 1000)
  
  
      // 构造请求参数
      const params = new URLSearchParams();
      params.append('phoneNumber', userMobile.value);
  
  
      // 构造请求头
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('tenant-id', '1');
  
      // 发送请求
      fetch('/admin-api/crm/enterprise-inquiry-log/getVerificationCode', {
        method: 'POST',
        headers: headers,
        body: params
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('Error:', error);
  
        });
    }
  
  
  }
  
  const phoneBlur = () => {
    const reg = /^1[0-9]{10}$/
    if (!reg.test(userMobile.value)) {
      phoneTipRef.value.style.display = 'block'
    } else {
      phoneTipRef.value.style.display = 'none'
    }
  }
  
  const phoneInput = () => {
    console.log('userMobile.value', userMobile.value)
    userMobile.value = userMobile.value.replace(/[^0-9]/g, "")
    const reg = /^[0-9]{10}$/
    if (reg.test(userMobile.value)) {
      phoneTipRef.value.style.display = 'none'
    }
  }
  
  const inputyanzm = () => {
    yanzm.value = yanzm.value.replace(/[^0-9]/g, "")
  }
  
  const agree = (num) => {
    if (num == 0) {
      checked1.value = true
      showMessageBox.value = false
    } else {
      showMessageBox.value = false
    }
  }
  
  const login = () => {
    const reg = /^1[0-9]{10}$/
    if (!userMobile.value) {
      ElMessage.error('请输入手机号')
      console.log(userMobile.value);
      return;
    }
    if (!reg.test(userMobile.value)) {
      ElMessage.error('请输入正确的手机号')
      return;
    }
    if (!yanzm.value) {
      ElMessage.error('请输入验证码')
      return;
    } else {
      // 构造请求参数
      const params = new URLSearchParams();
      params.append('phoneNumber', userMobile.value);
      params.append('code', yanzm.value);
  
      // 构造请求头
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('tenant-id', '1');
  
      // 发送请求
      fetch('/admin-api/crm/enterprise-inquiry-log/login', {
        method: 'POST',
        headers: headers,
        body: params
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 400) {     
            ElMessage.error('请输入正确的验证码')
          } else {
            router.push({
              path: '/enterpriseInfo',
              query: { smsuuid: data.data,
                userMobile:userMobile.value
              }
            })
          }
          // 在这里处理响应数据，data.data 就是 uuid
          // 在发送请求成功后
          // 导航到其他页面，并将数据作为路由参数传递
  
        })
        .catch(error => {
          console.error('Error:', error);
          // 在这里处理错误
        });
  
    }
  
  }
  </script>
  
  <style lang="scss" scoped>
  .input {
    position: relative;
  
    .phone-tip {
      position: absolute;
      color: #ff0000;
      font-size: 12px;
      display: none;
      top: 2.5rem;
    }
  }
  
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    //选中
    border-color: 1px solid #3b82f6 !important;
    background-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  
    &::after {
      // 里面的对钩
      border-color: #3b82f6;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 7px;
      width: 5px;
    }
  }
  
  :deep(.el-checkbox__inner) {
    //大小
    border-color: 1px solid #3b82f6 !important;
    background-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }
  
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
    //对钩的位置
    top: 2px;
    left: 4px;
  }</style>
  